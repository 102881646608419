import './bbcl'

window.addEventListener('scroll', () => {
    if (window.scrollY > 50) {
        if (!document.body.classList.contains('little-navbar')) {
            document.body.classList.add('little-navbar')
        }
    } else if (document.body.classList.contains('little-navbar')) {
        document.body.classList.remove('little-navbar')
    }
})

window.BBCL.mixins.push({
    data() {
        return {
            headerSidebarTabsActiveTab: 'sections-tab',
            showSidebar: false,
            showLogin: false
        }
    },
    watch: {
        showSidebar: {
            immediate: true,
            handler(show) {
                if (show) {
                    document.body.classList.add('sidebar-show')

                    if (this.headerSidebarTabsActiveTab === 'news-tab') {
                        this.$store.commit('BBCL/CLEAR_NOTIFICATION_COUNT')
                    }
                } else {
                    document.body.classList.remove('sidebar-show')
                }
            }
        }
    },
    methods: {
        toggleNewsTab() {
            if (this.headerSidebarTabsActiveTab !== 'news-tab') {
                this.$store.commit('BBCL/CLEAR_NOTIFICATION_COUNT')
                this.headerSidebarTabsActiveTab = 'news-tab'
            } else {
                this.headerSidebarTabsActiveTab = 'sections-tab'
            }
        }
    }
})
