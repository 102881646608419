import './bundle'
import lscache from 'lscache'

window.BBCL.mixins.push({
    computed: {
        automaticDarkMode: {
            get() {
                return this.$store.state['BBCL'].automaticDarkMode
            },
            set(value) {
                this.$store.commit('BBCL/SET_AUTOMATIC_DARK_MODE', value)
            }
        },
        darkMode: {
            get() {
                return this.$store.state['BBCL'].darkMode
            },
            set(value) {
                this.$store.commit('BBCL/SET_DARK_MODE', value)
            }
        }
    },
    methods: {
        goUp() {
            try {
                window.scroll({
                    top: 0,
                    behavior: 'smooth'
                })
            } catch {
                window.scroll(0, 0)
            }
        },
        markRead(index) {
            this.$store.commit('BBCL/MARK_READED', index)
        }
    }
})

window.BBCL.plugins.push((Vue, store) => {
    let automaticDarkModeTimeoutId

    function fetchNews() {
        Vue.axios.get(`https://www.pagina7.cl/static/news.json?t=${Date.now()}`).then(({ data }) => {
            store.commit('BBCL/PROCESS_NEWS', data)
        })
    }

    function tryToSetDarkMode() {
        const now = new Date()

        if (now.getHours() < 7 || now.getHours() >= 21) {
            if (!document.body.classList.contains('dark-mode')) {
                document.body.classList.add('dark-mode')
            }
        } else {
            if (document.body.classList.contains('dark-mode')) {
                document.body.classList.remove('dark-mode')
            }
        }
    }

    function automaticDarkModeTimeout() {
        tryToSetDarkMode()
        automaticDarkModeTimeoutId = setTimeout(automaticDarkModeTimeout, 60000)
    }

    function startAutomaticDarkMode() {
        clearTimeout(automaticDarkModeTimeoutId)

        tryToSetDarkMode()

        automaticDarkModeTimeoutId = setTimeout(automaticDarkModeTimeout, (60 - new Date().getSeconds()) * 1000)
    }

    function stopAutomaticDarkMode() {
        clearTimeout(automaticDarkModeTimeoutId)
        if (!store.state.BBCL.darkMode && document.body.classList.contains('dark-mode')) {
            document.body.classList.remove('dark-mode')
        }
    }

    function articleToNew(article, markAsNew = false) {
        return {
            id: article.ID,
            title: article.post_title,
            url: article.post_URL,
            timestamp: article.post_date_timestamp,
            dateHour: article.post_date_hour,
            new: markAsNew
        }
    }

    store.registerModule('BBCL', {
        namespaced: true,
        state() {
            return {
                darkMode: lscache.get('darkMode'),
                automaticDarkMode: lscache.get('automaticDarkMode'),
                news: lscache.get('news') || [],
                lastView: lscache.get('lastView') || Date.now()
            }
        },
        getters: {
            notReaded(state) {
                let notReaded = String(
                    state.news.reduce((notReaded, article) => (article.new ? notReaded + 1 : notReaded), 0)
                )

                if (notReaded > 9) {
                    notReaded = '9+'
                } else if (notReaded === '0') {
                    notReaded = ''
                }

                return notReaded
            },
            notificationCount(state) {
                let notificationCount = String(
                    state.news.reduce(
                        (accumulator, article) =>
                            article.timestamp * 1000 > state.lastView ? accumulator + 1 : accumulator,
                        0
                    )
                )

                if (notificationCount > 9) {
                    notificationCount = '9+'
                } else if (notificationCount === '0') {
                    notificationCount = ''
                }

                return notificationCount
            }
        },
        mutations: {
            SET_DARK_MODE(state, value) {
                lscache.set('darkMode', value)
                state.darkMode = value

                if (value) {
                    state.automaticDarkMode = false
                    lscache.set('automaticDarkMode', false)
                }

                if (window.ga) {
                    if (value) {
                        window.ga('send', 'event', 'Modo Oscuro', 'Activado')
                    } else {
                        window.ga('send', 'event', 'Modo Oscuro', 'Desactivado')
                    }
                }
            },
            SET_AUTOMATIC_DARK_MODE(state, value) {
                lscache.set('automaticDarkMode', value)
                state.automaticDarkMode = value

                if (value) {
                    state.darkMode = false
                    lscache.set('darkMode', false)
                }

                if (window.ga) {
                    if (value) {
                        window.ga('send', 'event', 'Modo Oscuro', 'Activado (Automático)')
                    } else {
                        window.ga('send', 'event', 'Modo Oscuro', 'Desactivado (Automático)')
                    }
                }
            },
            PROCESS_NEWS(state, articles) {
                if (state.news.length === 0) {
                    state.news = articles.map(article => articleToNew(article))
                    lscache.set('news', state.news)
                } else {
                    const news = articles
                        .map(article => articleToNew(article, true))
                        .filter(article => article.timestamp > state.news[0].timestamp)

                    if (news.length) {
                        state.news = news.concat(state.news).slice(0, 10)
                        lscache.set('news', state.news)
                    }
                }
            },
            MARK_READED(state, newIndex) {
                if (state.news[newIndex]) {
                    Vue.set(state.news[newIndex], 'new', false)
                    lscache.set('news', state.news)
                }
            },
            CLEAR_NOTIFICATION_COUNT(state) {
                state.lastView = Date.now()
                lscache.set('lastView', state.lastView)
            }
        }
    })

    store.watch(
        state => state.BBCL.darkMode,
        value => {
            if (value) {
                document.body.classList.add('dark-mode')
            } else if (!store.state.BBCL.automaticDarkMode) {
                document.body.classList.remove('dark-mode')
            }
        },
        {
            immediate: true
        }
    )

    store.watch(
        state => state.BBCL.automaticDarkMode,
        value => {
            if (value) {
                startAutomaticDarkMode()
            } else {
                stopAutomaticDarkMode()
            }
        },
        {
            immediate: true
        }
    )

    fetchNews()
    setInterval(fetchNews, 60000)
})
